import React from "react"

import CtaButton from "@common/CtaButton"
import { ReactComponent as ContactArt } from "@static/contact_art.svg"

import {
  ContactSection,
  StyledContainer,
  ImageWrapper,
  ContactFormWrapper,
  Title,
  Form,
  FormGroup,
  ButtonWrapper,
  ControlLabel,
  Bar,
  Editorial,
  Parallax1,
  Parallax2,
  Parallax3,
  Parallax4,
} from "./style"

const Contact = () => {
  return (
    <ContactSection id="contact">
      <StyledContainer>
        <Editorial
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28 "
          preserveAspectRatio="none"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 
        58-18 88-18s
        58 18 88 18 
        58-18 88-18 
        58 18 88 18
        v44h-352z"
            />
          </defs>
          <Parallax1>
            <use xlinkHref="#gentle-wave" x="50" y="3" fill="#0779a1" />
          </Parallax1>
          <Parallax2>
            <use xlinkHref="#gentle-wave" x="50" y="0" fill="#099ccf" />
          </Parallax2>
          <Parallax3>
            <use xlinkHref="#gentle-wave" x="50" y="9" fill="#85d6f3" />
          </Parallax3>
          <Parallax4>
            <use xlinkHref="#gentle-wave" x="50" y="6" fill="#09BFEC" />
          </Parallax4>
        </Editorial>
        <ImageWrapper>
          <ContactArt />
        </ImageWrapper>
        <ContactFormWrapper>
          <Title>Contact us!</Title>
          <Form>
            <FormGroup>
              <input type="text" required="required" name="name" id="name" />
              <ControlLabel htmlFor="name">Name*</ControlLabel>
              <Bar />
            </FormGroup>
            <FormGroup>
              <input type="text" required="required" name="email" id="email" />
              <ControlLabel htmlFor="input">Email*</ControlLabel>
              <Bar />
            </FormGroup>
            <FormGroup>
              <textarea
                required="required"
                name="message"
                id="message"
              ></textarea>
              <ControlLabel htmlFor="message">Message*</ControlLabel>
              <Bar />
            </FormGroup>
            <ButtonWrapper>
              <CtaButton text="Send" small />
            </ButtonWrapper>
          </Form>
        </ContactFormWrapper>
      </StyledContainer>
    </ContactSection>
  )
}

export default Contact
