import React, { useEffect, useState } from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Scrollspy from "react-scrollspy"
import { Link } from "gatsby"

import { Container } from "@components/global"
import {
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
} from "./style"

import { ReactComponent as MenuIcon } from "@static/icons/menu.svg"
import logo from "@static/logo.svg"

const NavBar = ({ color, transp, navItems }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [transparent, setTransparent] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    if (typeof window !== "undefined" && window.scrollY !== 0) {
      setScrollPosition(window.scrollY)
    }
  }, [])

  useEffect(() => {
    const setScollPositionCallback = () => setScrollPosition(window.scrollY)

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", setScollPositionCallback)
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", setScollPositionCallback)
      }
    }
  }, [])

  useEffect(() => {
    if (transp) {
      scrollPosition >= 20 ? setTransparent(false) : setTransparent(true)
    }
  }, [transp, scrollPosition])

  const toggleMobileMenu = () => {
    if (mobileMenuOpen && transp && scrollPosition <= 20) {
      setTransparent(true)
    } else {
      setTransparent(false)
    }
    setMobileMenuOpen(!mobileMenuOpen)
  }

  const closeMobileMenu = () => {
    if (mobileMenuOpen) {
      setMobileMenuOpen(false)
    }
  }

  const getNavAnchorLink = item => {
    if (item === "Devices") {
      return (
        <Link
          to="/devices-modal/"
          state={{
            modal: true,
            noScroll: true,
          }}
          onClick={closeMobileMenu}
        >
          {item}
        </Link>
      )
    } else {
      return (
        <AnchorLink
          href={`#${item
            .toLowerCase()
            .split(" ")
            .join("_")}`}
          onClick={closeMobileMenu}
        >
          {item}
        </AnchorLink>
      )
    }
  }

  const getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <Scrollspy
        items={[
          ...navItems.map(item =>
            item
              .toLowerCase()
              .split(" ")
              .join("_")
          ),
        ]}
        currentClassName="active"
        mobile={mobile}
      >
        {navItems.map(navItem => (
          <NavItem key={navItem}>{getNavAnchorLink(navItem)}</NavItem>
        ))}
      </Scrollspy>
    </NavListWrapper>
  )

  return (
    <Nav color={color} transparent={transparent}>
      <StyledContainer>
        <Brand>
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </Brand>
        <Mobile>
          <button onClick={toggleMobileMenu}>
            <MenuIcon />
          </button>
        </Mobile>

        <Mobile hide>{getNavList({ mobile: false })}</Mobile>
      </StyledContainer>
      <Mobile>
        {mobileMenuOpen && (
          <MobileMenu>
            <Container>{getNavList({ mobile: true })}</Container>
          </MobileMenu>
        )}
      </Mobile>
    </Nav>
  )
}

export default NavBar
