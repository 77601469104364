import styled, { keyframes } from "styled-components"

const moveForever1 = keyframes`
  from {
    transform: translate(85px, 0%);
  }

  to {
    transform: translate(-90px, 0%);
  }
`
const moveForever2 = keyframes`
  from {
    transform: translate(-90px, 0%);
  }

  to {
    transform: translate(85px, 0%);
  }
`
const moveForever3 = keyframes`
  from {
    transform: translate(85px, 0%);
  }

  to {
    transform: translate(-90px, 0%);
  }
`
const moveForever4 = keyframes`
  from {
    transform: translate(-90px, 0%);
  }

  to {
    transform: translate(85px, 0%);
  }
`

export const ContactSection = styled.section`
  background-image: linear-gradient(#09bfec, #09bfec);
  background-size: auto 70%;
  background-position: bottom;
  background-repeat: no-repeat;
`

export const StyledContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin: 0;
  padding: 0 5%;
  width: 100%;
  margin-top: 5%;

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: flex;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: none;
  }
`

export const ContactFormWrapper = styled.div`
  display: flex;
  background: #fff;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 25px -2px rgba(0, 255, 255, 0.45);
  width: 100%;
  height: auto;
  margin: 5vh 0;
  margin-left: auto;
  padding: 2rem 3rem;
  border-radius: 30px;
  z-index: 5;

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

export const Title = styled.h2`
  color: #007099;
  font-weight: 700;
  user-select: none;
`

export const Form = styled.form`
  width: 100%;
`

export const ControlLabel = styled.label`
  position: absolute;
  top: 0.25rem;
  pointer-events: none;
  padding-left: 0.125rem;
  z-index: 1;
  color: #007099;
  font-size: 1rem;
  font-weight: normal;
  transition: all 0.28s ease;
`

export const FormGroup = styled.div`
  display: block;
  position: relative;
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
  width: 100%;

  input {
    height: 1.9rem;
    display: block;
    background: none;
    padding: 0.125rem 0.125rem 0.0625rem;
    font-size: 1rem;
    border-width: 0;
    border-color: transparent;
    line-height: 1.9;
    width: 100%;
    color: transparent;
    transition: all 0.28s ease;
    box-shadow: none;
  }

  input:focus,
  input:valid,
  input.form-file,
  input.has-value,
  textarea:focus,
  textarea:valid,
  textarea.form-file,
  textarea.has-value {
    color: #007099;
  }

  input:focus
    ~ ${ControlLabel},
    input:valid
    ~ ${ControlLabel},
    input.form-file
    ~ ${ControlLabel},
    input.has-value
    ~ ${ControlLabel},
    textarea:focus
    ~ ${ControlLabel},
    textarea:valid
    ~ ${ControlLabel},
    textarea.form-file
    ~ ${ControlLabel},
    textarea.has-value
    ~ ${ControlLabel} {
    font-size: 0.8rem;
    color: #007099;
    top: -1rem;
    left: 0;
  }

  input:focus,
  textarea:focus {
    outline: none;
  }

  input:focus ~ ${ControlLabel}, textarea:focus ~ ${ControlLabel} {
    color: #007099;
  }

  textarea {
    resize: none;
    display: block;
    background: none;
    padding: 0.125rem 0.125rem 0.0625rem;
    font-size: 1rem;
    border-width: 0;
    border-color: transparent;
    line-height: 1.9;
    width: 100%;
    color: transparent;
    transition: all 0.28s ease;
    box-shadow: none;
  }

  textarea:focus ~ .bar::before {
    width: 100%;
    left: 0;
  }
`

export const Bar = styled.i`
  position: relative;
  border-bottom: 0.0625rem solid #007099;
  display: block;

  &::before {
    content: "";
    height: 0.125rem;
    width: 0;
    left: 50%;
    bottom: -0.0625rem;
    position: absolute;
    background: #007099;
    transition: left 0.28s ease, width 0.28s ease;
    z-index: 2;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Separator = styled.div`
  position: relative;
  width: 100%;
  background-image: linear-gradient(
    to right,
    #0baee7,
    #00b9eb,
    #00c5ed,
    #00cfee,
    #00daee
  );
`

export const Editorial = styled.svg`
  display: block;
  width: 100%;
  height: 40px;
  max-height: 40px;
  margin: 0;
  z-index: 5;
  top: calc(30% - 40px);
  position: absolute;
  left: 0px;
  float: left;
`
export const Parallax1 = styled.g`
  & > use {
    animation: ${moveForever1} 10s linear infinite;
  }

  & > use:nth-child(1) {
    animation-delay: -2s;
  }
`
export const Parallax2 = styled.g`
  & > use {
    animation: ${moveForever2} 8s linear infinite;
  }

  & > use:nth-child(1) {
    animation-delay: -2s;
  }
`
export const Parallax3 = styled.g`
  & > use {
    animation: ${moveForever3} 6s linear infinite;
  }

  & > use:nth-child(1) {
    animation-delay: -2s;
  }
`
export const Parallax4 = styled.g`
  & > use {
    animation: ${moveForever4} 4s linear infinite;
  }

  & > use:nth-child(1) {
    animation-delay: -2s;
  }
`
