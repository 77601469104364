import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"

import SEO from "@common/seo.js"
import NavBar from "@common/NavBar"

import theme from "@styles/theme"
import GlobalStyles from "@styles/GlobalStyles"

const Layout = ({
  title = "Home",
  transparent = true,
  color,
  navItems,
  children,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <SEO title={title} />
        <GlobalStyles />
        <NavBar transp={transparent} color={color} navItems={navItems} />
        {children}
      </>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
