import styled from "styled-components"
import { Container } from "@components/global"

export const FooterSection = styled.footer`
  background: #007099;
  color: #fff;
  padding: 1rem 0;
`

export const StyledContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
`

export const NewsletterWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;

  input {
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    border-color: #00ffff;
    padding: 10px;
    padding-left: 15px;
    flex: 2;
  }

  input:focus {
    outline: none;
  }
`

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-flow: column;
    align-items: center;
  }
`

export const SocialWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
`

export const Text = styled.h5`
  ${props => props.theme.font_size.xsmall};
`

export const IconsWrapper = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
    margin: 0 0.5rem;
  }
`

export const Copyright = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  h5 {
    margin: 0;
  }
`
