import React from "react"

import CtaButton from "@common/CtaButton"

import { ReactComponent as Facebook } from "@static/icons/facebook.svg"
import { ReactComponent as Twitter } from "@static/icons/twitter.svg"
import { ReactComponent as Instagram } from "@static/icons/instagram.svg"
import { ReactComponent as LinkedIn } from "@static/icons/linkedIn.svg"

import {
  FooterSection,
  StyledContainer,
  NewsletterWrapper,
  SocialWrapper,
  IconsWrapper,
  Copyright,
  Text,
  Form,
} from "./style"

const Footer = () => {
  return (
    <FooterSection>
      <StyledContainer>
        <NewsletterWrapper>
          <Text>Subscribe To Our Newsletter!</Text>
          <Form>
            <input type="email" placeholder="Enter email"></input>
            <CtaButton text="Sign up" small />
          </Form>
        </NewsletterWrapper>
        <SocialWrapper>
          <Text>Follow us on social media!</Text>
          <IconsWrapper>
            <Facebook />
            <Twitter />
            <Instagram />
            <LinkedIn />
          </IconsWrapper>
        </SocialWrapper>
        <Copyright>
          <Text>&copy; Copyright {new Date().getFullYear()}, Biox</Text>
        </Copyright>
      </StyledContainer>
    </FooterSection>
  )
}

export default Footer
